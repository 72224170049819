   .section {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 50px 60px;
  }
  
  .section-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .section-left {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 220px 40px;
    text-align: center;
    color: #fff;
    background: linear-gradient(180deg, rgba(128, 55, 248, 0.3) 0%, rgba(77, 43, 134, 0.3) 100%);
    backdrop-filter: blur(2px);
    border-radius: 10px;
  }
  
  .section-text {
    font-size: 18px;
    line-height: 1.5;
    margin: 0;
  }
  
  .section-right {
    width: 120%;
    height: auto;
  }

  @media screen and (max-width: 1024px) {
    .section-content {
      flex-direction: column;
      align-items: flex-start;
      margin-top: -50px !important;
    }
  
    .section-left {
      display: flex;
      margin: 0 auto;
      width: 92%;
      padding: 50px;
    }
  
    .section-text {
      padding-right: 0;
    }

    .section-right {
      width: 180%;
      height: auto;
      display: flex;
      padding-left: 38px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .section-content {
      flex-direction: column;
      align-items: flex-start;
    }

    .section-left {
      display: flex;
      margin: 0 auto;
      width: 100%;
      padding: 50px;
    }

    .section-right {
      width: 100%;
      height: auto;
      display: flex;
      /* padding-left: 38px; */
      align-items: center;
      padding: 0 !important;
    }
  
    .section-text {
      padding-right: 0;
    }
  }

  @media screen and (max-width: 425px) {

    .section{
      padding: 30px;
      margin-top: 40px;
    }
    .section-content {
      flex-direction: column;
      align-items: flex-start;
    }

    .section-left {
      display: flex;
      margin: 0 auto;
      width: 100%;
      padding: 30px 10px;
    }

    .section-right {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      padding: 0 !important;
    }
  
    .section-text {
      font-size: 16px;
    }
  }
    
