 .how-its-working {
  max-width: 1440px;
  margin: 0 auto;
  padding: 50px 60px;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading-large {
  margin-bottom: 50px;
}

.section-heading {
  font-size: 36px;
  text-align: center;
  color: #fff;
}

.section-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.p-container {
  width: calc(100% / 3 - 20px);
  margin-bottom: 50px;
}

.section-subheading {
  font-size: 24px;
  margin-bottom: 20px;
  color: #fff;
  position: relative;
}

.section-subheading::after{
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background: #fff;
  top: 42px;
  left:2px;
}

.writing-content {
  font-size: 18px;
  line-height: 1.5;
  margin: 0;
  color: #fff;
  margin-top: 50px;
}

@media screen and (max-width: 768px) {
  .p-container {
    width: 100%;
  }
}

@media screen and (max-width: 425px) {

  .how-its-working{
    padding: 30px;
  }

  .section-heading{
    font-size: 34px;           
  }

  .p-container {
    width: 100%;
  }

  .writing-content {
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    color: #fff;
    margin-top: 40px;
    font-weight: 400;
  }
}


