@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  width: 100%;
  overflow-x: hidden;
}

.background {
  background: url(../../assets/img/logo/gradient-image.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
