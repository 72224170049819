   nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 30px 60px;
  }
  
  .logo img {
    height: 60px;
  }
  
  .quick-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .quick-links a {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
    margin-left: 20px;
  }
  
  @media screen and (max-width: 768px) {
    nav {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  
    .quick-links {
      margin-top: 20px;
      width: 50%;
      /* justify-content: space-evenly; */
    }
  
    .quick-links a {
      margin: 0;
    }
  }

  @media screen and (max-width: 425px) {
    nav {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      align-items: center;
    }
  
    .quick-links {
      width: 80%;
      margin: 0;
      /* justify-content: space-evenly; */
    }
  
    .quick-links a {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      margin-left: 44px;
    }
  }
  
  