.image-section {
  max-width: 1440px;
  margin: 0 auto;
  padding: 50px 60px;
  padding-top: 0 ;
}

.image-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
 .image{
  width: 100%;
  max-width: 1420px;
  height: auto;
 }

 @media screen and (max-width: 425px) {

  .image-section{
    padding: 30px;
    margin-top: -30px;
  }
}
  