  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 70px 60px;
  }
  
  .header-image img {
    width: 100%;
  }
  
  .header-content {
    max-width: 600px;
  }
  
  .header-content h1 {
    font-size: 54px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 2px;
    color: #fff;
  }
  
  .header-content p {
    font-size: 18px;
    margin: 50px 0;
    text-align: center;
    padding: 0 18px;
    color: #fff;
  }
  
  .header-content button {
    background: linear-gradient(91.86deg, #8438FF 1.59%, #472A79 98.9%);
    border: 1px solid #FFFFFF;
    color: #fff;
    padding: 18px 28px;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    margin: 0 auto;
  }

  @media screen and (max-width: 1024px) {
    header {
      flex-direction: row;
      margin-top: 0;
      padding-top: 0;
    }

    .header-image {
      width: 60%;
    }
  
    .header-content {
      text-align: center;
      margin-top: 50px;
    }
  
    .header-content h1 {
      font-size: 32px;
    }
  
    .header-content p {
      font-size: 15px;
    }

    .header-content p {
      font-size: 18px;
      margin: 50px 0;
      text-align: center;
      padding: 0;
      color: #fff;
    }
  }
  
  @media screen and (max-width: 768px) {
    header {
      flex-direction: column;
      
    }
  
    .header-content {
      text-align: center;
      margin-top: 50px;
    }
  
    .header-content h1 {
      font-size: 36px;
    }
  
    .header-content p {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 425px) {
    header {
      flex-direction: column;
      padding: 30px;
    }
  
    .header-content {
      text-align: center;
      margin-top: 50px;
    }
  
    .header-content h1 {
      font-size: 34px;
      font-weight: 500;
    }
  
    .header-content p {
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.4px;
    }

    .header-content button {
      background: linear-gradient(91.86deg, #8438FF 1.59%, #472A79 98.9%);
      border: 1px solid #FFFFFF;
      color: #fff;
      padding: 18px 30px;
      border-radius: 5px;
      font-size: 14px;
      cursor: pointer;
      display: flex;
      margin: 0 auto;
    }
  }
  