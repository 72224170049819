.slack-card-container {
  max-width: 1440px;
  margin: 20px auto;
}

.slack-content,
.slack-content-left {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  margin: 50px auto;
  gap: 10px;
}

.slack-content {
  flex-direction: row;
}

.slack-content-left {
  flex-direction: row-reverse;
}

.image-div {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  background-color: #f6f6f6;
  padding: 15px;
  width: 510px;
  height: 300px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.content-div {
  width: 500px;
}

.content-div h3,
.content-div p {
  text-align: center;
  color: white;
}

.image-div img {
  height: auto;
  max-width: 100%;
  border-radius: 15px;
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .slack-content,
  .slack-content-left {
    flex-direction: column;
    margin: 10px auto;
    gap: 10px;
  }

  .image-div,
  .content-div {
    width: 100%;
  }
}
