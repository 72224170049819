body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -febkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9fafb !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.example {
  margin: 20px 0;

  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}
.card {
  margin-bottom: 1.5rem;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #c8ced3;
  border-radius: 0.25rem;
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #f0f3f5;
  border-bottom: 1px solid #c8ced3;
}

.card.how-to-signup-step {
  min-height: 300px;
}

.ant-radio-checked .ant-radio-inner {
  border-color: red !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: red;
}

.ant-radio:hover .ant-radio-inner {
  border-color: red;
}

.cl-custom-tooltip-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.cl-cut-text,
.cl-input-description,
.cl-badge-tag-timetracker-entry,
.time-tracker-recorder .cl-badge-tag,
.time-tracker-recorder .cl-badge-same-entries {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cl-listing-project-client {
  white-space: nowrap;
  overflow: hidden;
}

.cl-entry__project__dot {
  display: inline-block;
  margin-right: 0.7143rem;
  width: 0.4286rem;
  height: 0.4286rem;
  vertical-align: middle;
  border-radius: 50%;
}

.cl-notification__dot {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
  border-radius: 50%;
  background-color: black;
}

.cl-custom-tooltip-col-right {
  display: flex;
  justify-content: space-between;
  text-align: right;
  margin-left: 0.7143rem;
}

.cl-custom-tooltip {
  position: relative;
}

.cl-custom-tooltip .cl-top {
  width: 320px;
  top: -1.4286rem;
  left: 50%;
  transform: translate(-50%, -100%);
  padding: 0.7143rem;
  border-radius: 2px;
  position: absolute;
  z-index: 1070;
  display: none;
}

.cl-main-horizontal-chart-container--progress {
  height: 25px;
  cursor: pointer;
}

.cl-dashboard-table-col-3-2 {
  width: 70%;
}

*,
::before,
::after {
  box-sizing: border-box;
}

.cl-overflow-auto {
  overflow: auto;
}

.cl-m-0 {
  margin: 0 !important;
}

.cl-w-100 {
  width: 100% !important;
}

.cl-w-10 {
  width: 10% !important;
}

.cl-w-50 {
  width: 50% !important;
}

.cl-text-right {
  text-align: right !important;
}

.cl-text-left {
  text-align: left !important;
}

.cl-pl-2,
.cl-px-2 {
  padding-left: 0.7143rem !important;
}

.cl-main-horizontal-chart-container td {
  padding: 5px 10px;
  vertical-align: middle;
}

.cl-main-horizontal-chart-container--label {
  min-width: 200px;
  max-width: 200px;
  width: 40%;
  text-align: left;
}

.cl-text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

td {
  display: table-cell;
  vertical-align: inherit;
  text-align: unset;
  padding: 1px;
}

table {
  border-spacing: 2px;
  border-collapse: separate;
  text-indent: 0;
  border-collapse: collapse;
}

.cl-card {
  word-wrap: break-word;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: grey;
  border-radius: 0.3rem;
}

.image-upload.center {
  display: text;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.image-upload__preview {
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  height: 100px;
  width: 100px;
  border-radius: 15px;
  padding: 1px;
}

.image-upload__preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.cl-container-fluid {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

.cl-page-header-wrapper {
  margin-bottom: 2.8572rem;
}

.cl-align-items-center {
  align-items: center !important;
}

.cl-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -100px;
  margin-left: -10px;
}

.cl-mb-md-0,
.cl-my-md-0 {
  margin-bottom: 0 !important;
}

.cl-mb-3,
.cl-my-3 {
  margin-bottom: 1.4286rem !important;
}

.cl-justify-content-md-start {
  justify-content: flex-start !important;
}

.cl-justify-content-between {
  justify-content: space-between !important;
}

.cl-d-flex {
  display: flex !important;
}

.cl-col-md-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.cl-col-md-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.cl-col-md-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.cl-d-md-none {
  display: none !important;
}

.cl-btn-group,
.cl-btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.cl-align-items-sm-center {
  align-items: center !important;
}

.cl-justify-content-sm-end {
  justify-content: flex-end !important;
}

.cl-flex-sm-row {
  flex-direction: row !important;
}

.cl-flex-column {
  flex-direction: column !important;
}

.cl-page-header-action {
  margin-right: 0.7143rem;
}

.cl-w-md-auto {
  width: auto !important;
}

.cl-mb-sm-0,
.cl-my-sm-0 {
  margin-bottom: 0 !important;
}

.cl-mr-sm-2,
.cl-mx-sm-2 {
  margin-right: 0.7143rem !important;
}

.cl-mr-0,
.cl-mx-0 {
  margin-right: 0 !important;
}

.cl-dropdown-toggle.cl-text-left {
  padding-right: 1.78575rem;
}

.cl-btn-group > .cl-btn,
.cl-btn-group-vertical > .cl-btn {
  position: relative;
  flex: 1 1 auto;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

.cl-dropdown-toggle {
  transform: rotate(0);
  color: #666;
}

.cl-btn-outline-light,
.cl-btn-outline-light.cl-active,
.cl-show > .cl-btn-outline-light.cl-dropdown-toggle {
  border-color: #c6d2d9;
  color: #666;
  background: #fefefe;
  min-width: inherit;
  text-transform: none;
}

.cl-btn {
  text-transform: uppercase;
  min-width: 7.143rem;
}

.cl-text-capitalize {
  text-transform: capitalize !important;
}

.cl-text-left {
  text-align: left !important;
}

.cl-dropdown-toggle {
  white-space: nowrap;
}

.cl-btn-outline-light {
  color: #e4eaee;
  border-color: #e4eaee;
}

.cl-datepicker-wrapper {
  width: 230px;
}

.cl-relative {
  position: relative;
}

.cl-datepicker-wrapper .cl-datepicker-fake-input {
  position: absolute;
  width: 230px;
  z-index: 1;
  opacity: 1;
}

.cl-btn-outline-light,
.cl-btn-outline-light.cl-active,
.cl-show > .cl-btn-outline-light.cl-dropdown-toggle {
  border-color: #c6d2d9;
  color: #666;
  background: #fefefe;
  min-width: inherit;
  text-transform: none;
}

.pointer {
  cursor: pointer;
}

.cl-col-xl-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.cl-col-lg-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.cl-col-xl-custom,
.cl-col-xl-custom-auto,
.cl-col-xl-custom-12,
.cl-col-xl-custom-11,
.cl-col-xl-custom-10,
.cl-col-xl-custom-9,
.cl-col-xl-custom-8,
.cl-col-xl-custom-7,
.cl-col-xl-custom-6,
.cl-col-xl-custom-5,
.cl-col-xl-custom-4,
.cl-col-xl-custom-3,
.cl-col-xl-custom-2,
.cl-col-xl-custom-1,
.cl-col-lg-custom,
.cl-col-lg-custom-auto,
.cl-col-lg-custom-12,
.cl-col-lg-custom-11,
.cl-col-lg-custom-10,
.cl-col-lg-custom-9,
.cl-col-lg-custom-8,
.cl-col-lg-custom-7,
.cl-col-lg-custom-6,
.cl-col-lg-custom-5,
.cl-col-lg-custom-4,
.cl-col-lg-custom-3,
.cl-col-lg-custom-2,
.cl-col-lg-custom-1,
.cl-col-xl,
.cl-col-xl-auto,
.cl-col-xl-12,
.cl-col-xl-11,
.cl-col-xl-10,
.cl-col-xl-9,
.cl-col-xl-8,
.cl-col-xl-7,
.cl-col-xl-6,
.cl-col-xl-5,
.cl-col-xl-4,
.cl-col-xl-3,
.cl-col-xl-2,
.cl-col-xl-1,
.cl-col-lg,
.cl-col-lg-auto,
.cl-col-lg-12,
.cl-col-lg-11,
.cl-col-lg-10,
.cl-col-lg-9,
.cl-col-lg-8,
.cl-col-lg-7,
.cl-col-lg-6,
.cl-col-lg-5,
.cl-col-lg-4,
.cl-col-lg-3,
.cl-col-lg-2,
.cl-col-lg-1,
.cl-col-md,
.cl-col-md-auto,
.cl-col-md-12,
.cl-col-md-11,
.cl-col-md-10,
.cl-col-md-9,
.cl-col-md-8,
.cl-col-md-7,
.cl-col-md-6,
.cl-col-md-5,
.cl-col-md-4,
.cl-col-md-3,
.cl-col-md-2,
.cl-col-md-1,
.cl-col-sm,
.cl-col-sm-auto,
.cl-col-sm-12,
.cl-col-sm-11,
.cl-col-sm-10,
.cl-col-sm-9,
.cl-col-sm-8,
.cl-col-sm-7,
.cl-col-sm-6,
.cl-col-sm-5,
.cl-col-sm-4,
.cl-col-sm-3,
.cl-col-sm-2,
.cl-col-sm-1,
.cl-col,
.cl-col-auto,
.cl-col-12,
.cl-col-11,
.cl-col-10,
.cl-col-9,
.cl-col-8,
.cl-col-7,
.cl-col-6,
.cl-col-5,
.cl-col-4,
.cl-col-3,
.cl-col-2,
.cl-col-1 {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.cl-col-xl-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.cl-card-header:first-child {
  border-radius: 1px 1px 0 0;
}

.cl-card-header {
  display: flex;
  min-height: 40px;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(221, 221, 221);
}

.cl-pl-0,
.cl-px-0 {
  padding-left: 0 !important;
}

.cl-flex-md-row {
  flex-direction: row !important;
}

.cl-card-body {
  padding: 1.4286rem;
}

.cl-p-0 {
  padding: 0 !important;
}

.cl-main-chart-wrapper {
  border-bottom: 1px solid #c6d2d9;
}

.cl-main-chart-container {
  padding: 1.4286rem;
}

.chartjs-size-monitor,
.chartjs-size-monitor-expand,
.chartjs-size-monitor-shrink {
  position: absolute;
  direction: ltr;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
  visibility: hidden;
  z-index: -1;
}

.chartjs-size-monitor,
.chartjs-size-monitor-expand,
.chartjs-size-monitor-shrink {
  position: absolute;
  direction: ltr;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
  visibility: hidden;
  z-index: -1;
}

.chartjs-size-monitor,
.chartjs-size-monitor-expand,
.chartjs-size-monitor-shrink {
  position: absolute;
  direction: ltr;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
  visibility: hidden;
  z-index: -1;
}

.chartjs-render-monitor {
  animation: chartjs-render-animation 0.001s;
}

.dashboard__doughnut-chart__canvas {
  max-width: 300px;
  margin: 0 auto;
}

.cl-pt-2,
.cl-py-2 {
  padding-top: 0.7143rem !important;
}

.cl-pt-1,
.cl-py-1 {
  padding-top: 0.35715rem !important;
}

.cl-ml-lg-2,
.cl-mx-lg-2 {
  margin-left: 0.7143rem !important;
}

h6,
.cl-h6 {
  color: #999;
}

.cl-mb-0,
.cl-my-0 {
  margin-bottom: 0 !important;
}

.cl-page-wrapper {
  padding: 2.8572rem 0.7143rem;
}

.cl-mb-0,
.cl-my-0 {
  margin-bottom: 0 !important;
}

.cl-mb-1,
.cl-my-1 {
  margin-bottom: 1 !important;
}

h5,
.cl-h5,
h6,
.cl-h6 {
  font-size: 0.8571rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
.cl-h1,
.cl-h2,
.cl-h3,
.cl-h4,
.cl-h5,
.cl-h6 {
  margin-bottom: 1.07145rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  color: inherit;
}

.cl-component-row {
  margin: 0.2rem 1.4286rem 0.3rem;
}

.cl-cut-text-wrapper {
  flex: 1;
  min-width: 0;
}

.cl-cut-text,
.cl-input-description,
.cl-badge-tag-timetracker-entry,
.time-tracker-recorder .cl-badge-tag,
.time-tracker-recorder .cl-badge-same-entries {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.entry__project__dot {
  display: inline-block;
  margin-right: 5px;
  width: 5px;
  height: 5px;
  vertical-align: middle;
  border-radius: 50%;
}

.cl-dashboard-most-tracked-action {
  width: 6.4287rem;
  text-align: right;
  margin-left: 1.4286rem;
}

.cl-dashboard-most-tracked-start {
  display: none;
}

.text-align-center {
  text-align: center;
}

.subtitle {
  flex: auto;
  font-size: 20px;
}

.title-h1 {
  font: bold;
  font-stretch: expanded;
}

.live-dashboard-container {
  text-align: center;
}

.box-container {
  color: #efe9e9;
  width: 1600px;
  height: 800px;
  margin: auto;
}

.dashboard-row {
  display: flex;
  margin-left: 100px;
}

.innerbox {
  height: 250px;
  width: 280px;
  border: 1px solid white;
  background-color: rgb(204, 118, 20);
  font-size: medium;
}

.text {
  margin-left: -80px;
}

.profilename {
  margin-top: -80px;
}

.bold {
  margin-top: -13px;
}

.name {
  margin-top: 20px;
  margin-left: -200px;
  font-size: small;
  font-display: bold;
}

.para {
  margin-top: -2px;
  margin-left: 20px;
}

.circle {
  position: absolute;
  margin-left: 65px;
  margin-top: auto;
  height: 7px;
  width: 7px;
  background-color: #f4feff;
  border-radius: 50%;
}

.date {
  margin-top: 50px;
}

.badge-image {
  height: 9px;
  width: 9px;
  margin: auto;
}

/* LiveDashboard.css */
.cardDiv {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
  overflow: auto;
  justify-content: center;
}
.cardDiv .ant-card:hover {
  width: 300px !important;
}
.pro_img {
  position: relative;
}
.pro_img2 {
  position: absolute;
  color: black;
  background-color: white;
  top: 45%;
  left: 60%;
  border-radius: 10px;
  padding: 0px 5px;
}

/* sidebar.css */
.simplebar-offset {
  background-color: white;
}

/* Navbar.css And Tracking.css */
.ant-input-group-wrapper .ant-input,
.ant-input-group-wrapper .ant-input-group-addon,
.ant-input-group-wrapper .ant-btn {
  border: none !important;
}
.ant-space-item .ant-select {
  background-color: #f9fafb;
  border: 2px solid #e8eaed;
  border-radius: 13px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent !important;
  border: none !important;
  border-radius: none !important;
}

/* Dashboard.css And Project.css */
.ant-input-wrapper,
.ant-input-group {
  background-color: white;
  border: 2px solid #e6e6e6;
  border-radius: 13px;
  padding: 2px !important;
}

/* Navbar.css */
.mainNavContainer {
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
/* Project.css */
.projectList .ant-list-split .ant-list-item {
  border-bottom: none !important;
}

/* Users.css */
.usersList .ant-list-split .ant-list-item {
  border-bottom: none !important;
}

.usersList .ant-list-item-meta-content {
  width: initial;
}

/* UserSettings.css */
.userSetting .card-body {
  padding: 0;
}
.userSettingList .ant-card {
  width: 550px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(207, 214, 222, 0.25);
}
.userSetting {
  margin-left: 5px;
}
.userSettingList .ant-card-head,
.userSettingList .ant-card-bordered {
  border-bottom: none;
  border: none;
}
.userSettingList .btn-link {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.userSettingList .ant-form-item {
  margin: 0 0 10px;
}
.userSettingList .ant-card-body {
  padding: 5px 20px;
}
.userSettingList .ant-input,
.userSettingList .ant-input-affix-wrapper,
.userSettingList .ant-input-number,
.userSettingList .ant-select {
  background-color: #f9fafb;
  border: 1px solid #e8eaed;
  border-radius: 13px;
  width: 100%;
}
.userSettingList .ant-input,
.userSettingList .ant-input:hover,
.userSettingList .ant-input:focus,
.userSettingList .ant-input-number:hover,
.userSettingList .ant-input-number:focus {
  outline: none;
  border-right-width: none;
  box-shadow: none;
  border-color: #e6e6e6;
}
/* UserActivityDashboard.css */
.UserActivityDashboard .ant-space-item .ant-select {
  background-color: white !important;
  border: 2px solid #e6e6e6 !important;
  border-radius: 13px;
}
.mostTracked .ant-list-item {
  display: block;
}
.mostTracked .rc-virtual-list-holder {
  width: 100%;
}
.mostTracked .ant-select-selector,
.mostTracked .ant-select-arrow {
  border: none !important ;
  background-color: transparent !important;
  color: #5ca0f0 !important;
}

.btn-gradient,
.footer-btn {
  background: linear-gradient(91.86deg, #8438ff 1.59%, #472a79 98.9%);
}

.dashboard {
  width: 829px !important;
  height: 538px !important;
}

.number {
  position: relative;
}

.number::after {
  content: "";
  position: absolute;
  width: 300px;
  height: 3px;
  background-color: #472a79;
  top: 36px;
  left: -2px;
}

.landing-page-container {
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}

.para-container {
  background: linear-gradient(
    180deg,
    rgba(128, 55, 248, 0.3) 0%,
    rgba(77, 43, 134, 0.3) 100%
  );
  backdrop-filter: blur(2px);
}

.container-margin {
  margin-top: 100px;
}

.container-margin-bottom {
  margin-bottom: 100px;
}

.footer-container {
  background-color: #020058;
}

body {
  /* background: url(./image/gradient-image.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.nav-container {
  padding-top: 30px;
}

/* UserTask.css */
.userTasks .data:nth-child(even) {
  background-color: #f9fafb;
}
.userTasks .data:nth-child(odd) {
  background-color: #ffffff;
}

/* AllModal.css */
.ant-modal-content {
  border-radius: 15px !important;
}
.ant-modal-header {
  border-radius: 15px 15px 0 0 !important;
}
.ant-modal-content .ant-select.ant-select-in-form-item,
.ant-modal-content .ant-input,
.ant-modal-content .ant-picker,
.userSettingList .ant-picker {
  background-color: #f9fafb !important;
  border: 1px solid #e8eaed !important;
  border-radius: 13px !important;
}

/* input field   */
.ant-input-focused,
.ant-input:focus,
.ant-form-item-control-input-content {
  border-color: none !important;
  border-right-width: none !important;
  box-shadow: none !important;
  outline: none !important;
}
/* select/drop-down */
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  box-shadow: none !important;
}

/* TrackingRequest.css */
.ant-table-thead > tr > th {
  background: #f9fafb !important;
  border-bottom: 2px solid #e9ebef !important;
  color: #9198a2 !important;
}
.ant-table-tbody > tr > td {
  background: #fdfdfd !important;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 15px !important;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 15px !important;
}
.ant-table-container table > tbody > tr:last-child td:first-child {
  border-bottom-left-radius: 15px !important;
}
.ant-table-container table > tbody > tr:last-child td:last-child {
  border-bottom-right-radius: 15px !important;
}

/* UserTask.css */
.userTasks .ant-btn-icon-only {
  height: 32px;
  width: 50px;
}

/* Users.css */
.usersList .ant-switch-checked {
  background-color: #2664f2 !important;
}

/* Teams.css */
.TeamsList .ant-list-split .ant-list-item {
  background-color: white;
  border-bottom: none !important;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: 15px;
  padding: 10px;
  margin: 15px 0px;
}

/* Tooltip.css */
.ant-tooltip-inner {
  background-color: white !important;
  border-radius: 15px !important;
  color: black !important;
}
.ant-tooltip-arrow-content {
  --antd-arrow-background-color: white !important;
}

/* Notifications.css */
.ant-segmented-item-selected {
  border-radius: 10px !important;
}

/* Tracking Stop Button css */
.stop-button {
  background-color: #f05251 !important;
  border: 1px solid #f05251 !important;
  color: white !important;
}

/* ant-table-pagination.css */
.ant-table-pagination.ant-pagination {
  position: absolute !important;
  right: 0.5% !important;
  bottom: 100% !important;
}
.ant-table {
  margin: 50px 0px !important;
  background: transparent !important;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1) !important;
  border-radius: 15px !important;
}
.ant-select-clear {
  top: 45% !important;
  background: #f9fafb !important;
}
.ant-table-selection {
  padding-left: 10px !important;
}

/* UserTask.css */
.userTasks .ant-btn-icon-only {
  height: 32px;
  width: 50px;
}
.userTasks .css-1e7nfcw {
  background-color: transparent !important;
  border-radius: 15px !important;
}

/* BorderRadius */
.ant-select-dropdown,
.ant-input,
.ant-picker,
.ant-picker-panel-container,
.daterangepicker,
.ant-segmented,
.ant-popover-inner {
  border-radius: 12px !important;
}

.userSettingList .ant-input-suffix .ant-form-item-feedback-icon-error svg {
  margin-bottom: 5px !important;
}

/* Ant Table Pagination CSS */
.ant-pagination-item-link {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

/* Ant Button Loader */
.ant-btn > .ant-btn-loading-icon .anticon svg {
  vertical-align: 0 !important;
}

.btn-link.no-hover:hover {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #2664f2 !important;
  box-shadow: none !important;
}
