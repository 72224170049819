.about {
  max-width: 1440px;
  margin: 0 auto;
  padding: 50px 60px;
}

.about-heading {
  text-align: center;
  margin-bottom: 50px;
}

.about-heading h2 {
  font-size: 40px;
  font-weight: 600;
  color:#fff;
  margin: 0;
}

.about-content {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.about-left {
  width: 50%;
  padding: 0 30px;
}

.about-right {
  width: 50%;
  margin-top: 100px ;
  padding: 0 30px;
}

.about-left p,
.about-right p {
  font-size: 18px;
  line-height: 1.5;
  color: #fff;
  margin: 0;
}

@media screen and (max-width: 1024px) {
  .about-content {
    display: flex;
    justify-content: space-between;
  }

  .about-left,
  .about-right {
    width: 100%;
  }

  .about-left p,
.about-right p {
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 1.5;
  color: #fff;
  margin: 0;
}

  .about-left {
    width: 50%;
    padding: 0;
  }

  .about-right {
    width: 50%;
    top: 0;
    margin-top: 110px;
    padding: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .about-content {
    flex-direction: column;
    align-items: flex-start;
  }

  section.about{
    padding-top: 0;
  }

  .about-left,
  .about-right {
    width: 100%;
    text-align: center;
    font-size: 16px;
  }

  .about-right {
    top: 0;
    margin-top: 60px;
  }
}

@media screen and (max-width: 425px) {

  .about{
    padding: 30px;
    margin-top: 40px;
  }

  .about-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .about-heading h2{
      font-size: 34px;
      font-weight: 400;
  }

  .about-left,
  .about-right {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 200;
  }

  .about-left p,
  .about-right p {
    font-size: 14px;
    font-weight: 300;
  }

  .about-right {
    top: 0;
    margin-top: 60px;
  }
}

  