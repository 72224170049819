div.privacy-policy {
  background: url("../../../assets/img/logo/gradient-image.png");
  background-position: center;
  background-size: cover;
}

.policy-card {
  background-color: #fff;
  border-radius: 18px;
  padding: 10px 20px;
}

.policy-card-container {
  max-width: 1440px;
  margin: 0 auto;
}

.space {
  margin: 40px 0;
}
