.footer-section {
  padding: 50px 60px;
  background: #020058;
}

.footer-content {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  max-width: 100%;
  height: auto;
}

.footer-left {
  flex: 1;
}

.footer-center {
  flex: 2;
  text-align: center;
}

.footer-center h2 {
  color: #fff;
  font-size: 40px;
  margin-bottom: 60px !important;
}

.footer-center button {
  background: linear-gradient(91.86deg, #8438ff 1.59%, #472a79 98.9%);
  border: 1px solid #ffffff;
  color: #fff;
  padding: 18px 28px;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  margin: 0 auto;
}

.footer-color {
  color: white;
}

@media screen and (max-width: 768px) {
  .footer-content {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .footer-left {
    width: 60%;
    margin-bottom: 20px;
  }

  .footer-center h2 {
    color: #fff;
    font-size: 24px;
    margin-bottom: 40px !important;
    text-align: center;
  }

  .footer-center button {
    background: linear-gradient(91.86deg, #8438ff 1.59%, #472a79 98.9%);
    border: 1px solid #ffffff;
    color: #fff;
    padding: 16px 26px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    margin: 0 auto;
  }
}

@media screen and (max-width: 425px) {
  .footer-section {
    padding: 30px;
  }
  .footer-content {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .footer-left {
    width: 60%;
    margin-bottom: 20px;
  }

  .footer-center h2 {
    color: #fff;
    font-size: 24px;
    margin-bottom: 40px !important;
    text-align: center;
  }

  .footer-center button {
    background: linear-gradient(91.86deg, #8438ff 1.59%, #472a79 98.9%);
    border: 1px solid #ffffff;
    color: #fff;
    padding: 16px 26px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    margin: 0 auto;
  }
}

footer.footer-section .terms {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 76px;
}

footer.footer-section .contact-us {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

footer.footer-section .terms .content-container {
  display: flex;
  align-items: center;
}

footer.footer-section .terms .content-container div.vertical {
  height: 18px;
  width: 1px;
  background-color: #fff;
  margin: 0 10px;
}

footer.footer-section .terms .condition h4,
footer.footer-section .contact-us a,
footer.footer-section .terms .policy h4 {
  color: white !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  font-family: "poppins", sans-serif;
  text-decoration: underline !important;
  letter-spacing: 0.8px;
  cursor: pointer;
}

footer.footer-section .contact-us h4 {
  color: white !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  font-family: "poppins", sans-serif;
  letter-spacing: 0.8px;
  cursor: pointer;
}
